import React, {Component} from 'react';
class Copyright extends Component {
    render() {
	    return (
		<div className="">
			<div className="breadcrumbs overlay" style={{backgroundImage: 'url("../img/bg-home-2.jpg")' ,backgroundSize: "cover" , backgroundPosition: "center" , position: "relative" , padding: "150px 0px 75px"}}>
				<div className="container">
					<div className="bread-inner">
						<div className="row">
							<div className="col-12">
								<h2>COPYRIGHTS POLICY</h2>
								<ul className="bread-list">
									<li><a href="index.html">Home</a></li>
									<li><i className="icofont-simple-right"></i></li>
									<li className="active">COPYRIGHTS POLICY</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<section className="pf-details section">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="inner-content">
								<div className="body-text">
									<p><b>1. Introduction</b></p>
									<p>Welcome to Redi Xpress Logistics Inc. ("Company," "we," "us," or "our"). This Copyright Policy ("Policy") is designed to inform you about our practices regarding the protection of our intellectual property, including but not limited to our mobile application, website, and other digital assets ("Platform"). By accessing or using our Platform, you ("User," "you," or "your") agree to respect our intellectual property rights and comply with the terms and conditions set forth in this Policy.</p>
									<br />
									<p><b>2.Ownership of Intellectual Property</b></p>
									<p>All content, features, and functionality on our Platform, including but not limited to text, graphics, logos, icons, images, audio clips, and software, are the exclusive property of Redi Xpress Logistics Inc. and are protected by United States and international copyright laws.</p>
									<br />
									<p><b>3.Prohibition Against Unauthorized Use</b></p>
									<p>Unauthorized copying, modification, distribution, public display, or public performance of any part of our Platform is strictly prohibited. Any use of our Platform that violates the terms of this Policy may be considered an infringement of our intellectual property rights and may subject you to legal action.</p>
									<br />
									<p><b>4.Reporting Unauthorized Use</b></p>
									<p>If you become aware of any unauthorized use or infringement of our intellectual property, you are obligated to promptly notify us. Reports should be submitted to our designated agent at info@redixpresslogistics.com.</p>
									<br />
									<p><b>5.Legal Actions and Remedies</b></p>
									<p>We reserve the right to take any and all legal actions necessary to enforce our intellectual property rights, including but not limited to seeking injunctive relief, damages, attorney's fees, and court costs against any individual or entity that violates this Policy..</p>
									<br />
									<p><b>6.Indemnification</b></p>
									<p>You agree to indemnify, defend, and hold harmless Redi Xpress Logistics Inc., its officers, directors, employees, agents, licensors, and suppliers from and against all losses, expenses, damages, and costs, including reasonable attorneys' fees, resulting from any violation of this Policy or any activity related to your use of the Platform.</p>
									<br />
									<p><b>7.Governing Law and Jurisdiction</b></p>
									<p>This Policy shall be governed by and construed in accordance with the laws of the State of Ohio, without regard to its conflict of laws principles. Any legal action or proceeding arising under this Policy will be brought exclusively in the federal or state courts located in Ohio and the parties hereby irrevocably consent to the personal jurisdiction and venue therein.</p>
									<br />
									<p><b>8.Dispute Resolution</b></p>
									<p>Dispute Resolution</p>
									<br />
									<p><b>9. Modifications to Policy</b></p>
									<p>We reserve the right, at our sole discretion, to modify, add, or remove portions of this Copyright Policy at any time. Changes will become effective immediately upon their posting on our Platform. Your continued use of our Platform following the posting of changes to this Policy will be deemed your acceptance of those changes.</p>
									<br />
									<p><b>10. Waiver and Severability</b></p>
									<p>No waiver by Redi Xpress Logistics Inc. of any term or condition set forth in this Policy shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Redi Xpress Logistics Inc. to assert a right or provision under this Policy shall not constitute a waiver of such right or provision.</p>
									<br />
									<p><b>11. Contact Information</b></p>
									<p>For any questions, comments, or concerns regarding this Copyright Policy, you may contact us at info@redixpresslogistics.com.</p>
									<br />
									<p><b>12. Non-refundable Services</b></p>
									<p>This Copyright Policy and any other legal notices published by us on the Platform shall constitute the entire agreement between you and Redi Xpress Logistics Inc. concerning the use and protection of intellectual property related to our Platform.</p>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		);
    }
}
export default Copyright;