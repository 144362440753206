import React, {Component} from 'react';
class Cokkies extends Component {
    render() {
	    return (
		<div className="">
			<div className="breadcrumbs overlay" style={{backgroundImage: 'url("../img/bg-home-2.jpg")' ,backgroundSize: "cover" , backgroundPosition: "center" , position: "relative" , padding: "150px 0px 75px"}}>
				<div className="container">
					<div className="bread-inner">
						<div className="row">
							<div className="col-12">
								<h2>COOKIE POLICY</h2>
								<ul className="bread-list">
									<li><a href="index.html">Home</a></li>
									<li><i className="icofont-simple-right"></i></li>
									<li className="active">COOKIE POLICY</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<section className="pf-details section">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="inner-content">
								<div className="body-text">
									<p><b>1.Introduction</b></p>
									<p>Welcome to Redi Xpress Logistics Inc. ("Company," "we," "us," or "our"). This Cookie Policy explains how we use cookies and similar technologies to recognize you when you visit our mobile application and website ("Platform"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>
									<br />
									<p><b>2. What Are Cookies?</b></p>
									<p>Cookies are small data files that are placed on your computer or mobile device when you visit a website or use an online service. Cookies are widely used by online service providers to facilitate and help to make the interaction between users and websites, mobile apps, and online services faster and easier, as well as to provide reporting information.</p>
									<br />
									<p><b>3. Types of Cookies We Use</b></p>
									<p>We use several types of cookies on our Platform, including but not limited to:</p>
									<p><b>•	Session Cookies:</b>These are temporary cookies that expire when you close your browser or app.</p>
									<p><b>•	Persistent Cookies</b>These cookies remain on your device for a set period or until you delete them</p>
									<p><b>•	Third-Party Cookies</b>These are cookies set by third parties that we may use for various services, such as analytics or advertising.</p>
									<br />
									<p><b>4. Why We Use Cookies</b></p>
									<p>Cookies play an important role in helping us provide a personalized experience to you. Here are some of the ways we use cookies:</p>
									<p><b>•	To Authenticate Users</b>Cookies help us identify users so that you don't have to log in each time you visit our Platform</p>
									<p><b>•	To Improve Platform Performance</b>Cookies can also be used to speed up your future activities and experiences on our Platform</p>
									<p><b>•	For Analytics</b>We use cookies to understand user behavior and improve our Platform based on these insights.</p>
									<br />
									<p><b>5. Your Choices Regarding Cookies</b></p>
									<p>You have the right to decide whether to accept or reject cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to reject cookies, you may still use our Platform though your access to some functionality and areas may be restricted.</p>
									<br />
									<p><b>6. How to Control Cookies</b></p>
									<p>There are several ways to manage cookies and other tracking technologies. You can clear cookies from your browser, or change your browser settings to either reject all cookies or to receive a notification when a cookie is being placed on your device. For detailed instructions, please refer to your browser’s help menu.</p>
									<br />
									<p><b>7. Third-Party Cookies</b></p>
									<p>In some instances, we may use third-party cookies for purposes such as analytics, advertising, and social media interactions. These third-party cookies are governed by the respective third parties' privacy and cookie policies, and we recommend that you read their policies for more information.</p>
									<br />
									<p><b>8. Cookies and Personal Data</b></p>
									<p>Cookies themselves do not typically contain personal information. However, we may link the information we store in cookies to any personally identifiable information you submit while using our Platform. This helps us personalize your experience.</p>
									<br />
									<p><b>9. Changes to This Cookie Policy</b></p>
									<p>We may update this Cookie Policy from time to time to reflect changes to the cookies we use or for other operational, legal, or regulatory reasons. Any changes will be posted on this page, and your continued use of our Platform after such changes have been posted will constitute your acknowledgment of the modified Cookie Policy.</p>
									<br />
									<p><b>10. Contact Us</b></p>
									<p>If you have any questions or concerns about our use of cookies or this Cookie Policy, you can contact us at 
										<a href='info@redixpresslogistics.com.'>info@redixpresslogistics.com.</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		);
    }
}
export default Cokkies;