import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './pages/Header';
import Home from './pages/Home';
import Footer from './pages/Footer';
import Contact from "./pages/Contact";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Blog from "./pages/Blog";
import Driver from "./pages/Driver";
import Blog_Detail from "./pages/Blog_Detail";
import Faq from "./pages/Faq";
import Services from "./pages/Services";
import Cokkies from "./pages/Cokkies";
import Copyright from "./pages/Copyright";
import Shipping from "./pages/Shipping";
function App() {
  return (
    <>
    <Header/>
    <Routes>
       <Route path="/" element={<Home />} />
       <Route path="/contact" element={<Contact />} />
       <Route path="/about" element={<About />} />
       <Route path="/privacy" element={<Privacy />} />
       <Route path="/terms" element={<Terms />} />
       <Route path="/blog" element={<Blog />} />
       <Route path="/driver" element={<Driver />} />
       <Route exact path='/detail/:slug' element={<Blog_Detail/>} />
       <Route path="/Faq" element={<Faq />} />
       <Route path="/service" element={<Services />} />
       <Route path="/cookie-policy" element={<Cokkies />} />
       <Route path="/copyright-policy" element={<Copyright />} />
       <Route path="/shipping-policy" element={<Shipping />} />

    </Routes>
    <Footer/>
 </>
  
  );
}
export default App;