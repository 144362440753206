import React, {useEffect,useState} from 'react';
import parse from 'html-react-parser';
import { useParams } from "react-router-dom";

function  Blog_Detail() 
{
	const { slug } = useParams();
// USE EFFECT //

useEffect(() => {
    fetchItem();
      }, []);
  
  


// SET THE STATE //

  const [item, setItem] = useState({
  });
  

  
  
  
// FETCH INDIVIDUAL ITEM

  const fetchItem = async () => {
    const fetchItem = await fetch(`https://redixpresslogistics.com/courier/public/api/detail/${slug}`);
    const res = await fetchItem.json();
    
    setItem(res.data);
    
  };
  const regex = /(<([^>]+)>)/gi;


    return(
		<div>
			<div className="breadcrumbs overlay" style={{backgroundImage: 'url("../img/bread-bg.jpeg")' ,backgroundSize: "cover" , backgroundPosition: "center" , position: "relative" , padding: "150px 0px 75px"}}>
				<div className="container">
					<div className="bread-inner">
						<div className="row">
							<div className="col-12">
								<h2>Blog Detail</h2>
								<ul className="bread-list">
									<li><a href="index.html">Home</a></li>
									<li><i className="icofont-simple-right"></i></li>
									<li className="active">Blog Detail</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<section className="news-single section">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-12">
							<div className="row">
								<div className="col-12">
									<div className="single-main">
										<div className="news-head">
											<img src={item.image} alt="#" />
										</div>
										<h1 className="news-title">{item.title}</h1>
										<div className="meta">
											<div className="meta-left">
												<span className="author"><a href="#"><img src="/img/author1.jpg" alt="#" />Admin</a></span>
												<span className="date"><i className="fa fa-clock-o"> {item.created}</i></span>
											</div>
											
										</div>
										<div className="news-text">
										{item.description ? parse(item.description) : ''}
										</div>
										
									</div>
								</div>
								
							</div>
						</div>
						
					</div>
				</div>
			</section>
		</div>
	);
}
export default Blog_Detail;