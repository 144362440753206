import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Blog extends Component {
	constructor(props) {
		super(props)
		this.state = {
		   blogData: [],
		}
	 }
  
	 componentDidMount() {
		
		fetch(`https://redixpresslogistics.com/courier/public/api/bloglist`)
		.then((response) => response.json())
		.then((jsonResponse) => {
		   this.setState({ blogData: jsonResponse?.blogs })
		})

	 }
    render() {
		const regex = /(<([^>]+)>)/gi;

	    return (
		<div className="blog-page">
			<div className="breadcrumbs overlay"  style={{backgroundImage: 'url("../img/bread-bg.jpeg")' ,backgroundSize: "cover" , backgroundPosition: "center" , position: "relative" , padding: "150px 0px 75px"}}>
				<div className="container">
					<div className="bread-inner" >
						<div className="row">
							<div className="col-12">
								<h2>Our Blog</h2>
								<ul className="bread-list">
									<li><a href="index.html">Home</a></li>
									<li><i className="icofont-simple-right"></i></li>
									<li className="active">Blog</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
           
			<section className="blog section" id="blog">
					<div className="container">
						<div className="row">
						{this.state.blogData && this.state.blogData.map((blog) => (
							<div className="col-lg-4 col-md-6 col-12" key={blog.slug}>
								<div className="single-news">
									<div className="news-head">
										<img src={blog.image} alt="#" />
									</div>
									<div className="news-body">
										<div className="news-content">
											<h2><Link to={`/detail/${blog.slug}`}>{blog.title}</Link></h2>
											<p className="text">{parse(blog.description.toString().substring(0, 100))}</p>
											<div className="date"><i className="fa fa-clock-o"></i> {blog.created} - <i>By admin</i></div>
										</div>
									</div>
								</div>
							</div>
						        ))}
	
						</div>
					</div>
				</section>
		</div>
		);
    }
}
export default Blog;