import React, {Component} from 'react';
class Shipping extends Component {
    render() {
	    return (
		<div className="">
			<div className="breadcrumbs overlay" style={{backgroundImage: 'url("../img/bg-home-2.jpg")' ,backgroundSize: "cover" , backgroundPosition: "center" , position: "relative" , padding: "150px 0px 75px"}}>
				<div className="container">
					<div className="bread-inner">
						<div className="row">
							<div className="col-12">
								<h2>SHIPPING AND REFUND POLICY</h2>
								<ul className="bread-list">
									<li><a href="index.html">Home</a></li>
									<li><i className="icofont-simple-right"></i></li>
									<li className="active">SHIPPING AND REFUND POLICY</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<section className="pf-details section">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="inner-content">
								<div className="body-text">
									<p><b>1. Introduction</b></p>
									<p>Welcome to Redi Xpress Logistics Inc. ("Company," "we," "us," or "our"). This combined Shipping and Refund Policy ("Policy") governs the shipping and refund practices related to the courier delivery services ("Services") offered through our mobile application and website ("Platform"). By using our Platform and Services, you ("User," "you," or "your") agree to comply with and be bound by the terms and conditions set forth in this Policy.</p>
									<br />
									<p><b>2. Scope of Services</b></p>
									<p>This Policy applies to all shipping services provided by Redi Xpress Logistics Inc., including but not limited to, local, national, and future international shipping services. Our Services facilitate the transportation of goods, including medical supplies and e-commerce products, but exclude the delivery of food items. By using our Services, you acknowledge that you have read and understood the scope and limitations of the services we offer.</p>
									<br />
									<p><b>3. Shipping Rates</b></p>
									<p>Shipping rates are calculated based on various factors, including the distance of the shipment, the weight and dimensions of the package, and any additional services you may select. All shipping rates are subject to change at our sole discretion, and we reserve the right to apply surcharges or offer discounts as we deem appropriate. You agree to pay all applicable shipping rates and charges as calculated at the time of your shipment.</p>
									<br />
									<p><b>4. Delivery Timeframes</b></p>
									<p>We strive to deliver shipments within the timeframes indicated at the time of booking. However, delivery timeframes are estimates and are not guaranteed. Factors such as weather conditions, traffic, and other unforeseen circumstances may affect delivery times. We shall not be liable for any delays in delivery that are beyond our reasonable control.</p>
									<br />
									<p><b>5. Packaging Requirements</b></p>
									<p>You are responsible for adequately packaging your items to ensure their safe and secure delivery. Packaging must comply with any applicable laws and regulations and should be suitable for the type of goods being shipped. Failure to comply with proper packaging requirements may result in the refusal of your shipment, and we shall not be liable for any loss or damage arising from inadequate packaging.</p>
									<br />
									<p><b>6. Tracking and Notifications</b></p>
									<p>Upon confirmation of your shipment, you will receive a tracking number that allows you to monitor the status of your shipment through our Platform. We may also send notifications regarding the status of your shipment, including but not limited to, pickup, in-transit updates, and delivery confirmations. You agree to provide accurate contact information to facilitate such notifications.</p>
									<br />
									<p><b>7. Restricted Items</b></p>
									<p>We maintain a list of items that are restricted from shipment through our Services. This list includes, but is not limited to, hazardous materials, illegal substances, and perishable goods. You are responsible for ensuring that your shipment does not contain any restricted items, and we reserve the right to refuse or terminate any shipment containing restricted items, without liability.</p>
									<br />
									<p><b>8. Cancellation and Modification</b></p>
									<p>You may request the cancellation or modification of a shipment prior to its dispatch. However, cancellation or modification fees may apply depending on the timing and nature of the changes. We reserve the right to accept or reject any cancellation or modification requests at our sole discretion. No refunds will be issued for shipments that have already been dispatched.</p>
									<br />
									<p><b>9. Eligibility for Refunds</b></p>
									<p>Refunds may be issued under specific circumstances, such as failure to deliver within a significantly delayed timeframe that is not due to force majeure events, or loss of goods due to our negligence. Refunds are not automatic and must be requested in accordance with the procedures outlined in this Policy.</p>
									<br />
									<p><b>10. Timeframe for Filing a Refund</b></p>
									<p>All refund requests must be submitted within seven (7) days from the date of the scheduled delivery. Failure to submit a refund request within this timeframe will result in forfeiture of your eligibility for a refund.</p>
									<br />
									<p><b>11. Processing of Refunds</b></p>
									<p>Upon approval of a refund request, the refund will be processed using the original payment method within fourteen (14) business days. We reserve the right to deduct any applicable fees or charges before issuing a refund.</p>
									<br />
									<p><b>12. Non-refundable Services</b></p>
									<p>Certain services and fees are non-refundable, including but not limited to, rush delivery fees, special handling fees, and any other additional services that are successfully rendered.</p>
									<br />
									<p><b>13. Liability for Damaged or Lost Items</b></p>
									<p>We shall not be liable for any loss or damage to items that are improperly packaged, fragile, or not suitable for transport. Our liability for lost or damaged items is limited to the lesser of the actual value of the item or the coverage amount selected at the time of shipment, subject to applicable laws and regulations.</p>
									<br />
									<p><b>14. Indemnification Clause</b></p>
									<p>You agree to indemnify, defend, and hold harmless Redi Xpress Logistics Inc., its officers, directors, employees, and agents.</p>
									<br />
									<p><b>15. Governing Law</b></p>
									<p>This Policy, and any dispute or claim arising out of or in connection with it or its subject matter or formation, shall be governed by, and construed in accordance with, the laws of the State of Ohio. You irrevocably agree that the courts of the State of Ohio shall have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with this Policy or its subject matter or formation.</p>
									<br />
									<p><b>16. Dispute Resolution</b></p>
									<p>Any disputes or claims arising out of or in connection with this Policy shall be resolved through final and binding arbitration, rather than in court. The arbitration shall be conducted in the State of Ohio, under the then-current rules of the American Arbitration Association. Judgment on the arbitration award may be entered into any court having jurisdiction thereof.</p>
									<br />
									<p><b>17. Changes to Policy</b></p>
									<p>We reserve the right, at our sole discretion, to modify, add, or remove portions of this Policy at any time. Changes will become effective immediately upon being posted on our Platform. Your continued use of our Services following the posting of changes to this Policy will be deemed your acceptance of those changes.</p>
									<br />
									<p><b>18. Contact Information</b></p>
									<p>For any questions, comments, or concerns regarding this Policy, you may contact us at 
										<a href='info@redixpresslogistics.com.'>info@redixpresslogistics.com.</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		);
    }
}
export default Shipping;