import React, { Component } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import parse from "html-react-parser";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogData: [],
    };
  }

  componentDidMount() {
    fetch(`https://redixpresslogistics.com/courier/public/api/bloglist`)
      .then((response) => response.json())
      .then((jsonResponse) => {
        this.setState({ blogData: jsonResponse?.blogs });
      });
  }

  render() {
    return (
      <div className="medium-12 columns">
        <section className="slider">
          <OwlCarousel
            className="owl-theme"
            items={1}
            loop={true}
            nav={true}
            responsiveClass={true}
            responsive={{
              0: { items: 1, nav: false },
              768: { items: 1, nav: false },
              990: { nav: true },
            }}
            navText={[
              '<i class="fa fa-arrow-left" aria-hidden="true"></i>',
              '<i class="fa fa-arrow-right" aria-hidden="true"></i>',
            ]}
            dots={false}
          >
            <div className="item">
              <div className="single-slider overlay">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7 col-sm-7">
                      <div className="text">
                        <small>Welcome to Redixpress</small>
                        <h1>
                          Redi Xpress Your Full Service Medical & Crowdsource
                          Delivery Service
                        </h1>
                        <div className="button">
                          <a
                            href="https://redixpresslogistics.com/courier/public/"
                            className="btn"
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-sm-5">
                      <img src="img/slider-1.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="single-slider overlay">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7 col-sm-7">
                      <div className="text">
                        <small>Welcome to Redixpress</small>
                        <h1>Same day Medical Delivery</h1>
                        <div className="button">
                          <a
                            href="https://redixpresslogistics.com/courier/public/"
                            className="btn"
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-5 col-sm-5">
                      <img src="img/slider-2.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="single-slider overlay">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7 col-sm-7">
                      <div className="text">
                        <small>Welcome to Redixpress</small>
                        <h1>Scheduled Medical Delivery Services</h1>
                        <div className="button">
                          <a
                            href="https://redixpresslogistics.com/courier/public/"
                            className="btn"
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-sm-5">
                      <img src="img/slider-1.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="single-slider overlay">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7 col-sm-7">
                      <div className="text">
                        <small>Welcome to Redixpress</small>
                        <h1>Medical Supplies</h1>
                        <div className="button">
                          <a
                            href="https://redixpresslogistics.com/courier/public/"
                            className="btn"
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-5 col-sm-5">
                      <img src="img/slider-2.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="single-slider overlay">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7 col-sm-7">
                      <div className="text">
                        <small>Welcome to Redixpress</small>
                        <h1>Medical Prescription Delivery</h1>
                        <div className="button">
                          <a
                            href="https://redixpresslogistics.com/courier/public/"
                            className="btn"
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-5 col-sm-5">
                      <img src="img/slider-2.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
          ;
        </section>
        <section className="pf-details section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <div className="d-flex flex-column">
                  <img
                    className="img-fluid rounded align-self-end"
                    src="img/24service.png"
                    alt="website template image"
                  />
                </div>
              </div>
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <div class="section-title">
                  <h2>
                    Get to Know Our 24-7 Medical Courier and Delivery Services
                    Team
                  </h2>
                  <p>
                    Redi Xpress Logistics is a New York and Ohio-based medical
                    courier and e-commerce delivery services company that
                    specializes in transporting medical supplies, medical
                    prescriptions, labs, specimens, documents, and all other
                    item-based delivery requests. We can deliver everything from
                    floral arrangements and tires, to e-commerce purchases. We
                    proudly operate on a 24-7 schedule, meaning you can contact
                    us and place a request with our delivery team at any time of
                    the day, from anywhere in the world. We take our rush
                    delivery services incredibly seriously, providing you with
                    peace of mind that your items are going to get to their
                    destination quickly, swiftly, and efficiently. That’s our
                    promise here at Redi Xpress Logistics.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="wcu_about container-fluid bg-primary overflow-hidden px-lg-0">
          <div className="container feature px-lg-0">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="section-title">
                  <h2 className="text-white mb-4">Why Choose Us</h2>
                  <p className="text-white mb-4 pb-2">
                    There’s a reason we’re regarded as one of the fastest
                    courier services in the country. Our team operates out of
                    two states on a 24-7 basis, which means yes, we always have
                    someone on staff who is reviewing delivery requests and
                    forwarding them onto our fleet. The moment we receive a
                    request, we optimize the delivery route, contact our
                    drivers, and schedule the rush delivery as quickly as
                    possible. You don’t have any time to waste and neither do we
                    – we prioritize efficiency in everything we do. As a team of
                    expertly trained deliverers and drivers, we safeguard your
                    delivery so that it arrives in pristine condition. That’s
                    our guarantee.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 pe-lg-0" data-wow-delay="0.5s">
                <img
                  className="img-fluid"
                  src="img/why-choose-us.png"
                  alt="website template image"
                />
              </div>
            </div>
          </div>
        </div>
        <section className="driver-signup section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-12">
                <div className="section-title">
                  <h2>DELIVERY DRIVER WANTED</h2>
                  <p>
                    Are you a qualified commercial or delivery driver looking to
                    become part of our fleet? Get in contact with our team today
                    and we will schedule an interview ASAP.
                  </p>
                </div>
				<div className="button mt-20">
                    <Link to="/driver" className="btn">
                      Know More <i className="fa fa-long-arrow-right mx-1"></i>
                    </Link>
                  </div>
              </div>
              <div className="col-lg-5 col-12">
                <img
                  className="img-fluid rounded align-self-end"
                  src="img/about-img.jpg"
                  alt="website template image"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="driver-signup section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 col-12">
                <img
                  className="img-fluid rounded align-self-end"
                  src="img/loking-for-partners.jpg"
                  alt="website template image"
                />
              </div>
              <div className="col-lg-7 col-12">
                <div className="section-title">
                  <h2>Looking For Partners</h2>
                  <p>
                    Redixpress Logistics, a leading logistics shipping company,
                    is actively seeking partners to expand our global reach.
                    Join us to leverage our advanced shipping solutions,
                    ensuring timely and efficient deliveries. Partner with us
                    for unparalleled service and growth opportunities.
                  </p>
                </div>
                <div className="button mt-20">
                  <Link to="/contact" className="btn">  Get Started<i className="fa fa-long-arrow-right mx-1"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="call-action overlay">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="content">
                  <small>ABOUT DRIVERS</small>
                  <h2>
                    Need something delivered ASAP? Our experts will respond
                  </h2>

                  <div className="button mt-20">
                    <Link to="/contact" className="btn">
                      REQUEST A QUOTE <i className="fa fa-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="services section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  <h2>WHAT WE DELIVER</h2>
                  <p>
                    We are more than just a delivery service- we are a team you
                    can count on for fast transport
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="single-service">
                  <i class="fa fa-cubes" aria-hidden="true"></i>
                  <h4>Packages</h4>
                  <p>
                    Small, medium, large, oversize, overweight, and custom
                    package requests
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="single-service">
                  <i class="fa fa-building" aria-hidden="true"></i>
                  <h4>Documents</h4>
                  <p>
                    Passport delivery and filling,Visa delivery and filling,
                    Court filings, Real estate contracts, Court document
                    retrieval, Sensitive paperwork, Custom document requests.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="single-service">
                  <i class="fa fa-qrcode" aria-hidden="true"></i>
                  <h4>Healthcare</h4>
                  <p>
                    Medical samples,specimen, equipment,devices and supplies.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="single-service">
                  <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                  <h4>Floral</h4>
                  <p>
                    Wedding flowers, Funeral flowers, Event flowers,
                    pop-upflowers, Custom floral requests.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="single-service">
                  <i class="fa fa-crosshairs" aria-hidden="true"></i>
                  <h4>E-commerce</h4>
                  <p>Online purchase, Amazon packages, Dropshiping.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="single-service">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  <h4>Valuables</h4>
                  <p>
                    Artwork, Jewerly, Gems, Furniture, Glassware, Antiques,
                    Furs, Rugs, Etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="driver-signup section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-12">
                <div className="section-title">
                  <h2>ABOUT OUR DRIVERS</h2>
                  <p>
                    We carefully vet, choose, and work with every single one of
                    our drivers to ensure they can meet our commitments to
                    efficient driving, safe deliveries, and stellar customer
                    service. Our drivers come with a nationwide expertise that
                    makes them some of the best in the game. It’s one thing to
                    drive with speedy intent – but it’s a whole other thing to
                    handle products, documents, and medical supplies with care.
                    We look for drivers who can do both, which is why we are
                    thorough when we make driver hires. Check out our fleet
                    below.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-12">
                <img
                  className="img-fluid rounded align-self-end"
                  src="img/slider1.png"
                  alt="website template image"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="call-action overlay">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="content">
                  <h2>Explore Our Services</h2>
                  <p>
                    Redixpress is a premier provider of nationwide pharmacy
                    delivery services, specializing in efficient logistics
                    solutions. We are dedicated to facilitating the smooth
                    movement of goods within the industry, ensuring seamless
                    global trade through reliable land transport.
                  </p>
                  <div className="button">
                    <Link to="/service" className="btn">
                      Explore More <i className="fa fa-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="blog section" id="blog">
          <div className="container">
            <div className="row">
              {this.state.blogData &&
                this.state.blogData.map((blog) => (
                  <div className="col-lg-4 col-md-6 col-12" key={blog.slug}>
                    <div className="single-news">
                      <div className="news-head">
                        <img src={blog.image} alt="#" />
                      </div>
                      <div className="news-body">
                        <div className="news-content">
                          <h2>
                            <Link to={`/detail/${blog.slug}`}>
                              {blog.title}
                            </Link>
                          </h2>
                          <p className="text">
                            {parse(
                              blog.description.toString().substring(0, 100)
                            )}
                          </p>
                          <div className="date">
                            <i className="fa fa-clock-o"></i> {blog.created} -{" "}
                            <i>By admin</i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Home;
