import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
	
const Footer = () => {

	const [formData, setFormData] = useState({email: ''})

	const [errors, setErrors] = useState({})
  
	const handleChange = (e) => {
	  const { name, value } = e.target;
	  setFormData((prevData) => ({
		...prevData,
		[name]: value,
	  }));
	};
  
	const validateEmail = (email) => {
	  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	  return email.trim() !== '' && emailRegex.test(email) ? null : 'Enter a Valid Email Address';
	};
  
	const handleSubmit = (e) => {
	  e.preventDefault();
  
	  const { email } = formData;
	  const errors = {
		email: validateEmail(email),
	  };
  
	  // if error stops otherwise go
	  if (Object.values(errors).some((error) => error !== null)) {
		setErrors(errors);
		return;
	  }
  
	  // json object
	  const jsonData = {
		email
	  };
  
	  //json data console
	  console.log(jsonData);
	  
	  var myHeaders = new Headers();
	  myHeaders.append("Content-Type", "application/json");
  
	  var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: JSON.stringify(jsonData),
		redirect: 'follow'
	  };
  
	  fetch("https://redixpresslogistics.com/courier/public/api/savenewsletter", requestOptions)
		.then(response => response.text())
		.then(result => {
		  setFormData({ email: ''});
		  setErrors({});
		  Swal.fire( 'Thank you!','Subscribe our Newsletter! :)','success');
		})
		.catch(error => console.log('error', error));
  
	  //reset form data after submit
	  setFormData({ email: ''});
	  setErrors({});
	};

 
	    return (
			<footer id="footer" className="footer ">
				<div className="footer-top">
					<div className="container">
						<div className="row">
							<div className="col-lg-3 col-md-6 col-12">
								<div className="single-footer">
									<h2>Get in touch</h2>
									<ul className="time-sidual">
										<li className="day"><b>Address :</b> 1502 Brittain Rd #1058 Akron, OH 44310</li>
										<li className="day"><b>Phone :</b> 866-540-7194</li>
										<li className="day"><b>Email :</b> info@redixpresslogistics.com</li>
										
									</ul>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-12">
								<div className="single-footer f-link">
									<h2>Information</h2>
									<ul>
										<li><Link to="/terms"><i className="fa fa-caret-right" aria-hidden="true"></i>Terms & Conditions</Link></li>
										<li><Link to="/privacy"><i className="fa fa-caret-right" aria-hidden="true"></i>Privacy Policy</Link></li>
										{/* <li><a href="#"><i className="fa fa-caret-right" aria-hidden="true"></i>Request A Quote</a></li> */}
										<li><Link to="/faq"><i className="fa fa-caret-right" aria-hidden="true"></i>FAQ'S</Link></li>
										<li><Link to="/cookie-policy"><i className="fa fa-caret-right" aria-hidden="true"></i>COOKIE POLICY</Link></li>
										<li><Link to="/shipping-policy"><i className="fa fa-caret-right" aria-hidden="true"></i>SHIPPING AND REFUND POLICY</Link></li>
										<li><Link to="/copyright-policy"><i className="fa fa-caret-right" aria-hidden="true"></i>COPYRIGHTS POLICY</Link></li>
									</ul>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-12">
								<div className="single-footer">
									<h2>Newsletter</h2>
									<p>Subscribe to our newsletter and receive the latest tips via email.</p>
									<form method="post" action="" className="newsletter-inner">
										<input name="email" placeholder="Email Address" className="common-input" 
												required="" type="email" value={formData.email} onChange={handleChange} />
                                           {errors.email && <span className="error text-danger">{errors.email}</span>}
										<button className="button" onClick={handleSubmit}><i className="icofont icofont-paper-plane"></i></button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="copyright">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 col-md-12 col-12">
								<div className="copyright-content">
									<p>© Copyright 2024  |  All Rights Reserved by <a href="#" target="_blank">Redixpress.</a> </p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
	     );
}
export default Footer;