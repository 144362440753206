import React, {Component} from 'react';
import { Link } from 'react-router-dom';



class Services extends Component {
	
    render() {
	    return (
			<div>
			<div className="breadcrumbs overlay" style={{backgroundImage: 'url("../img/driver2.jpeg")' ,backgroundSize: "cover" , backgroundPosition: "center" , position: "relative" , padding: "150px 0px 75px"}}>
				<div className="container">
					<div className="bread-inner">
						<div className="row">
							<div className="col-12">
								<h2>Services</h2>
								<ul className="bread-list">
									<li><a href="index.html">Home</a></li>
									<li><i className="icofont-simple-right"></i></li>
									<li className="active">Services</li>
									<li className="active"></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<section className="full_service section" >
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title">
								
									<h2>WHAT WE DO</h2>
									<p>We offer specialized courier and rush delivery services that include</p>
								</div>
							</div>
						</div>
						<div className="full_service_row">
							<div className="full_service_box">
								<h3>Medical Courier Services</h3>
							</div>
							<div className="full_service_box">
								<h3>Same Day Delivery</h3>
							</div>
							<div className="full_service_box">
								<h3>Next Flight Out Services</h3>
							</div>
							<div className="full_service_box">
								<h3>Legal Courier Services</h3>
							</div>
							<div className="full_service_box">
								<h3>Remote Office Pickup</h3>
							</div>
							<div className="full_service_box">
								<h3>Trade Shows Pickup & Delivery</h3>
							</div>
						</div>
					</div>
			</section>
			<section className="how_it_work services section">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title">
								<h2>FAQ'S</h2>
							</div>
						</div>
					</div>
					<section className="news-single section fq_section">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-12">
							<section className="faq-section">
								<div className="faq" id="accordion">
									<div className="card">
										<div className="card-header" id="faqHeading-1">
											<div className="mb-0">
												<h5 className="faq-title" data-toggle="collapse" data-target="#faqCollapse-1" data-aria-expanded="true" data-aria-controls="faqCollapse-1">
													<span className="badge">1</span>What is Redi Xpress?
												</h5>
											</div>
										</div>
										<div id="faqCollapse-1" className="collapse" aria-labelledby="faqHeading-1" data-parent="#accordion">
											<div className="card-body">
												<p>Redi Xpress is crowd sourcing delivery company. We have a platform that connects individuals or business in need of delivery services with a network independent drivers or couriers who can fulfill these delivery requests.</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-header" id="faqHeading-2">
											<div className="mb-0">
												<h5 className="faq-title" data-toggle="collapse" data-target="#faqCollapse-2" data-aria-expanded="false" data-aria-controls="faqCollapse-2">
													<span className="badge">2</span>  How does your crowdsourcing delivery service work?
												</h5>
											</div>
										</div>
										<div id="faqCollapse-2" className="collapse" aria-labelledby="faqHeading-2" data-parent="#accordion">
											<div className="card-body">
												<p>Customers can submit delivery requests through our app or website, and our platform matches these requests with available drivers in the vicinity. Drivers then pick up and deliver the items to the specified destination.</p>
											</div>
										</div>
									</div>
									
									<div className="card">
										<div className="card-header" id="faqHeading-5">
											<div className="mb-0">
												<h5 className="faq-title" data-toggle="collapse" data-target="#faqCollapse-5" data-aria-expanded="false" data-aria-controls="faqCollapse-5">
													<span className="badge">5</span>  How can I place a delivery order?
												</h5>
											</div>
										</div>
										<div id="faqCollapse-5" className="collapse" aria-labelledby="faqHeading-5" data-parent="#accordion">
											<div className="card-body">
												<p> To place a delivery order, simply download our app or visit our website. Sign up or log in, enter the delivery details, and choose a driver from the available options. You can also schedule deliveries in advance.</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-header" id="faqHeading-6">
											<div className="mb-0">
												<h5 className="faq-title" data-toggle="collapse" data-target="#faqCollapse-6" data-aria-expanded="false" data-aria-controls="faqCollapse-6">
													<span className="badge">6</span>  How do you calculate delivery fees?
												</h5>
											</div>
										</div>
										<div id="faqCollapse-6" className="collapse" aria-labelledby="faqHeading-6" data-parent="#accordion">
											<div className="card-body">
												<p>Delivery fees are calculated based on factors such as the distance, size and weight of the items, and the urgency of the delivery. You'll receive a cost estimate before confirming your order.</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-header" id="faqHeading-7">
											<div className="mb-0">
												<h5 className="faq-title" data-toggle="collapse" data-target="#faqCollapse-7" data-aria-expanded="false" data-aria-controls="faqCollapse-7">
													<span className="badge">7</span>  Is my package insured during delivery?
												</h5>
											</div>
										</div>
										<div id="faqCollapse-7" className="collapse" aria-labelledby="faqHeading-7" data-parent="#accordion">
											<div className="card-body">
												<p>We offer insurance options for your packages during delivery. You can choose the level of coverage that suits your needs when placing an order.</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-header" id="faqHeading-8">
											<div className="mb-0">
												<h5 className="faq-title" data-toggle="collapse" data-target="#faqCollapse-8" data-aria-expanded="false" data-aria-controls="faqCollapse-8">
													<span className="badge">8</span>  Can I track my delivery in real-time?
												</h5>
											</div>
										</div>
										<div id="faqCollapse-8" className="collapse" aria-labelledby="faqHeading-8" data-parent="#accordion">
											<div className="card-body">
												<p>Yes, our platform provides real-time tracking so you can monitor the progress of your delivery from pickup to drop-off. You'll also receive notifications along the way.</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-header" id="faqHeading-9">
											<div className="mb-0">
												<h5 className="faq-title" data-toggle="collapse" data-target="#faqCollapse-9" data-aria-expanded="false" data-aria-controls="faqCollapse-9">
													<span className="badge">9</span> What types of items can I send using your service?
												</h5>
											</div>
										</div>
										<div id="faqCollapse-9" className="collapse" aria-labelledby="faqHeading-9" data-parent="#accordion">
											<div className="card-body">
												<p>You can use our service to send a wide range of items, including documents, packages, ecommerce, healthcare supplies, prescriptions, labs, specimens, and more. However, there may be restrictions on certain items, such as hazardous materials</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-header" id="faqHeading-10">
											<div className="mb-0">
												<h5 className="faq-title" data-toggle="collapse" data-target="#faqCollapse-10" data-aria-expanded="false" data-aria-controls="faqCollapse-10">
													<span className="badge">10</span>  How do I pay for the delivery service?
												</h5>
											</div>
										</div>
										<div id="faqCollapse-10" className="collapse" aria-labelledby="faqHeading-10" data-parent="#accordion">
											<div className="card-body">
												<p> We offer various payment options, including credit/debit cards, and digital wallets. You can choose your preferred payment method during the ordering process.</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-header" id="faqHeading-11">
											<div className="mb-0">
												<h5 className="faq-title" data-toggle="collapse" data-target="#faqCollapse-11" data-aria-expanded="false" data-aria-controls="faqCollapse-11">
													<span className="badge">11</span> What if I have a problem with my delivery or driver?
												</h5>
											</div>
										</div>
										<div id="faqCollapse-11" className="collapse" aria-labelledby="faqHeading-11" data-parent="#accordion">
											<div className="card-body">
												<p>If you encounter any issues with your delivery or driver, you can contact our customer support team through the app or website. We're here to assist you with any concerns.</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-header" id="faqHeading-7">
											<div className="mb-0">
												<h5 className="faq-title" data-toggle="collapse" data-target="#faqCollapse-12" data-aria-expanded="false" data-aria-controls="faqCollapse-12">
													<span className="badge">12</span>  Can I schedule a delivery for a specific time or date?
												</h5>
											</div>
										</div>
										<div id="faqCollapse-12" className="collapse" aria-labelledby="faqHeading-12" data-parent="#accordion">
											<div className="card-body">
												<p>Yes, you can schedule deliveries for a specific time or date that is convenient for you. Our platform allows for both immediate and scheduled deliveries.</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-header" id="faqHeading-13">
											<div className="mb-0">
												<h5 className="faq-title" data-toggle="collapse" data-target="#faqCollapse-13" data-aria-expanded="false" data-aria-controls="faqCollapse-13">
													<span className="badge">13</span>   Do you offer international delivery services?
												</h5>
											</div>
										</div>
										<div id="faqCollapse-13" className="collapse" aria-labelledby="faqHeading-13" data-parent="#accordion">
											<div className="card-body">
												<p>Currently, we primarily operate within the US. We do not offer international deliveries at this time.</p>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
						<div className="col-lg-4">
							<img src="img/delivery1.png" />
						</div>
					</div>
				</div>
			</section>
				</div>
			</section>
			</div>
	     );
    }
}
export default Services;
