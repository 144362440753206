import React, {Component} from 'react';
class Terms extends Component {
    render() {
	    return (
		<div className="">
			<div className="breadcrumbs overlay" style={{backgroundImage: 'url("../img/bg-home-2.jpg")' ,backgroundSize: "cover" , backgroundPosition: "center" , position: "relative" , padding: "150px 0px 75px"}}>
				<div className="container">
					<div className="bread-inner">
						<div className="row">
							<div className="col-12">
								<h2>Terms & Conditions</h2>
								<ul className="bread-list">
									<li><a href="index.html">Home</a></li>
									<li><i className="icofont-simple-right"></i></li>
									<li className="active">Terms & Conditions</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<section className="pf-details section">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="inner-content">
								<div className="body-text">
								
									<p><b>1.Introduction</b> </p>
									<p>Welcome to Redi Xpress Logistics Inc. ("Company," "we," "us," or "our"). These Terms and Conditions ("Terms") govern your access to and use of our mobile application and website ("Platform"), as well as the courier delivery services ("Services") offered through the Platform. By accessing or using the Platform, you agree to comply with and be bound by these Terms.</p>
									<hr></hr>
									<p><b>2.Definitions</b> </p>
									<ul>
										<li><b>User:</b> Any individual or entity that accesses or uses the Platform.</li>
										<li><b>Driver:</b> An individual or entity who performs delivery services through the Platform.</li>
										<li><b>Sender:</b> An individual or entity who initiates a delivery request via the Platform.</li>
										<li><b>Recipient:</b> The individual or entity designated to receive the delivered items.</li>
										<li><b>Service:</b> The courier delivery services offered through the Platform, specifically focusing on medical supplies and e-commerce products.</li>
										<li><b>Platform:</b> The mobile application and website operated by Redi Xpress Logistics Inc.</li>
									</ul>
									<p><b>3. Acceptance of Terms</b></p>
										<p>By accessing or using the Platform, you hereby acknowledge that you have read, 
											understood, and agree to be bound by these Terms. If you do not agree with these Terms,
											you must immediately cease use of the Platform and any Services offered therein.</p>
									<p><b>4. Modification of Terms</b></p>		
										<p>Redi Xpress Logistics Inc. reserves the right to modify, amend, or update these Terms at
											any time and for any reason. Changes will be effective immediately upon posting on the
											Platform. Your continued use of the Platform and Services after such modifications will 
											constitute your acknowledgment of the modified Terms and your agreement to abide and
											be bound by them.</p>
									<p><b>5. Eligibility and Account Creation</b></p>	
										<p>To use the Services, you must be at least 18 years of age and capable of forming a
											binding contract. You may be required to create an account ("Account") and provide
											certain personal information. You agree to provide accurate, current, and complete
											information during the registration process and to update such information to keep it
											accurate, current, and complete.</p>
									<p><b>6. User Responsibilities</b></p>
										<p>By using the Platform, you represent and warrant that:</p>
										<ul>
											<li>You have the legal capacity to enter into these Terms and to fulfill your obligations herein.</li>
											<li>You will comply with all applicable local, state, and federal laws and regulations concerning your use of the Platform and Services.</li>
											<li>You will not use the Platform for any unlawful or prohibited purpose.</li>
											<li>You will maintain the confidentiality of your Account information.</li>
										</ul>			
									<p><b>7. Service Description</b></p>	
									<p>Redi Xpress Logistics Inc. offers a courier delivery service through its Platform. The
										Service is designed to facilitate the delivery of medical supplies and e-commerce products. 
										It does not include food delivery. For details on shipping and refunds, please refer to our
										separate Shipping and Refund Policy.</p>

										<p><b>8. Pricing and Payment</b></p>	
									<p>The pricing for the Services will be as stated on the Platform at the time of your request.
										All payments are to be made through the payment methods available on the Platform. By 
										using the Services, you agree to abide by the pricing and payment terms as we may 
										update them from time to time.</p>

										<p><b>9. Delivery Process</b></p>	
									<p>Upon confirmation of your delivery request, a Driver will be assigned to fulfill the delivery. 
										You will be provided with real-time tracking and status updates through the Platform. Redi 
										Xpress Logistics Inc. is not responsible for any delays caused by factors beyond our 
										control, including but not limited to traffic, weather, or mechanical failures.</p>

										<p><b>10. Driver Requirements</b></p>	
									<p>All Drivers are subject to a thorough background check and must meet the qualifications 
										set forth by Redi Xpress Logistics Inc. Drivers are independent contractors and are not 
										employees of Redi Xpress Logistics Inc. They are solely responsible for the delivery 
										services they provide.</p>

										<p><b>11. User Conduct</b></p>	
									<p>Users are expected to conduct themselves in a lawful and respectful manner when using 
										the Platform and Services. Any behavior deemed harmful, harassing, or in violation of 
										these Terms may result in immediate termination of your Account and access to the 
										Platform.</p>

										<p><b>12. Data Privacy</b></p>	
									<p>Your privacy is important to us. All personal information collected through the Platform is 
										handled in accordance with our Privacy Policy, which is hereby incorporated by reference 
										into these Terms.</p>

										<p><b>13. Confidentiality</b></p>	
									<p>Users may have access to confidential information ("Confidential Information") while using 
										the Platform. You agree to maintain the confidentiality of such information and not 
										to disclose or use it for any purpose other than as necessary to use the Services.</p>

									<p><b>14. Dispute Resolution</b></p>	
									<p>Any disputes arising out of or relating to these Terms, the Platform, or the Services will be 
										resolved through binding arbitration, rather than in court, except for matters that can be 
										taken to small claims court. The arbitration will be conducted in the State of Ohio, USA, 
										and judgment on the arbitration award may be entered into any court having jurisdiction thereof.</p>

										<p><b>15. Limitation of Liability</b></p>	
									<p>To the fullest extent permitted by applicable law, Redi Xpress Logistics Inc. shall not be 
										liable for any indirect, incidental, special, consequential, or punitive damages, or any loss 
										of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, 
										goodwill, or other intangible losses, resulting from your use or inability to use the Platform or Services.</p>

										<p><b>16. Indemnification</b></p>	
									<p>You agree to indemnify, defend, and hold harmless Redi Xpress Logistics Inc., its officers, 
										directors, employees, agents, and third parties, for any losses, costs, liabilities, and 
										expenses (including reasonable attorneys' fees) relating to or arising out of your use of 
										or inability to use the Platform or Services, your violation of any terms of this Agreement 
										or your violation of any rights of a third party, or your violation of any applicable laws, rules, 
										or regulations.</p>

										<p><b>17. Intellectual Property</b></p>	
									<p>All content, trademarks, services marks, trade names, logos, and icons are the property of 
										Redi Xpress Logistics Inc. or its affiliates or agents and are protected by copyright laws 
										and international treaties and provisions. You agree not to delete the copyright or other 
										proprietary rights notice from any content.</p>

										<p><b>18. Third-Party Services</b></p>	
									<p>The Platform may contain links to third-party websites or services that are not owned or 
										controlled by Redi Xpress Logistics Inc. We have no control over, and assume no 
										responsibility for, the content, privacy policies, or practices of any third-party websites or services.</p>

										<p><b>19. Termination of Account</b></p>	
									<p>Redi Xpress Logistics Inc. reserves the right to terminate or suspend your Account and 
										access to the Platform and Services for conduct that we believe violates these Terms or 
										is harmful to other users of the Platform, us, or third parties, or for any other reason.</p>

										<p><b>20. Governing Law</b></p>	
									<p>These Terms shall be governed by and construed in accordance with the laws of the State 
										of Ohio, USA, without regard to its conflict of law principles.</p>

										<p><b>21. Non-Discrimination Policy</b></p>	
									<p>Redi Xpress Logistics Inc. is committed to providing an inclusive and welcoming 
										environment for all users of the Platform and Services. We do not discriminate on the basis 
										of race, color, religion, gender, gender expression, age, national origin, disability, marital 
										status, sexual orientation, or military status.</p>

										<p><b>22. Safety Measures</b></p>	
									<p>Redi Xpress Logistics Inc. prioritizes the safety of all Users and Drivers. We implement 
										various safety protocols and measures, but we cannot guarantee absolute safety. You 
										agree to use the Platform and Services at your own risk.</p>

										<p><b>23. Feedback and Reviews</b></p>	
									<p>Users are encouraged to provide feedback and reviews about the Services. By submitting 
										feedback, you grant Redi Xpress Logistics Inc. the right to use, publish, and modify your 
										feedback for any purpose without any obligation to compensate you.</p>

										<p><b>24. Severability</b></p>	
									<p>If any provision of these Terms is found to be invalid or unenforceable by a court of 
										competent jurisdiction, such provision shall be severed from the remainder of these Terms, 
										which will otherwise remain in full force and effect.</p>

										<p><b>25. Contact Information</b></p>	
									<p>For any questions about these Terms, the Platform, or the Services, please contact us at 
										info@redixpresslogistics.com.</p>	

									<p><b>2.</b> Any support email address and other contact information for support?</p>
									<p><b>Ans:</b> info@redixpresslogistics.com</p>
									<hr></hr>
									<p><b>3.</b> I know users will be required to create an account on the platform in order to be able to access the full functionalities of the platform. However, will there be social media plugins that allow signup/login via social media websites? This means your system can import existing user data from their social media accounts and create a profile with it on your website.</p>
									<p><b>Ans:</b> not now but in future we ll use google and Facebook signup for both customer and driver</p>
									<hr></hr>
									<p><b>4.</b> Will the platform have an in-app communication feature such as a chat or call feature?</p>
									<p><b>Ans:</b> in app chat</p>
									<hr></hr>
									<p><b>5.</b> In which states or regions will you be offering your delivery services at the start?</p>
									<p><b>Ans:</b> USA</p>
									<hr></hr>
									<p><b>6.</b> I know it's going to be a paid platform, but tell me more about billing and subscription plansespecially about your cancellation and refund practices. Anything related to payment for both drivers and clients, please.</p>
									<p><b>Ans:</b> </p>
									<p>For driver :Billing Cycle: Decide on the billing cycle, such as, alternative days, monthly, quarterly, or annually.</p>
									<p>For customers:Support a variety of payment methods to accommodate your users' preferences. Common options include credit/debit cards, PayPal, stripe etc and other online payment systems.</p>
									<p><b>Cancellation and Refund Policy</b></p>
									<p><b>1. Cancellation Policy:</b></p>
									<p><b>1.1 Subscription Cancellation:</b></p>
									<p>You have the right to cancel your subscription at any time. To cancel, please follow the steps outlined in your account settings on our platform. Cancellations can take effect immediately or at the end of the current billing period, depending on your preference.</p>
									<p><b>1.2 Refund Policy for Subscription Cancellation:</b></p>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;If you cancel your subscription before the end of your current billing period, you will continue to have access to the platform's premium features until the end &nbsp;&nbsp;&nbsp;of that period.</li>
									<li>&nbsp;&nbsp;&nbsp;We do not offer refunds or pro-rated refunds for partial billing periods.</li>
									</ul></p>
									<p><b>2. Refund Policy:</b></p>
									<p><b>2.1 General Refund Policy:</b></p>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;We are committed to providing a high-quality service and customer satisfaction. If you believe you are entitled to a refund, please contact our customer &nbsp;&nbsp;&nbsp;support team to discuss your situation.</li>
									<li>&nbsp;&nbsp;&nbsp;Refund requests are evaluated on a case-by-case basis.</li>
									</ul></p>
									<p><b>2.2 Eligibility for Refunds:</b></p>
									<p>Refunds may be considered in the following situations:</p>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;Technical issues or service interruptions that prevent you from accessing the platform.</li>
									<li>&nbsp;&nbsp;&nbsp;Unauthorized charges or billing errors.</li>
									<li>&nbsp;&nbsp;&nbsp;Any other exceptional circumstances that warrant a refund at our discretion.</li>
									</ul></p>
									<p><b>2.3 Refund Process:</b></p>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;To request a refund, please contact our customer support team via [contact information].</li>
									<li>&nbsp;&nbsp;&nbsp;Provide detailed information about the reason for your refund request, including any relevant documentation.</li>
									<li>&nbsp;&nbsp;&nbsp;Our team will review your request and respond to you within [specified timeframe].</li>
									<li>&nbsp;&nbsp;&nbsp;If your refund request is approved, the refund will be issued to the original payment method used for the purchase.</li>
									</ul></p>
									<p><b>2.4 Refund Amount:</b></p>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;The amount of the refund, if approved, will be determined based on the specific circumstances of your case.</li>
									<li>&nbsp;&nbsp;&nbsp;We reserve the right to issue partial refunds or full refunds as appropriate.</li>
									</ul></p>
									<p><b>2.5 Refund Timeframe:</b></p>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;Refunds, once approved, will be processed as quickly as possible. The time it takes for the refund to appear in your &nbsp;&nbsp;&nbsp;account may vary depending on your financial institution.</li>
									</ul></p>
									<p><b>3. Contact Information:</b></p>
									<p>If you have any questions or concerns regarding our cancellation and refund policy, please contact our customer support team at [contact information].</p>
									<p>Please ensure that you tailor this policy to align with your platform's offerings and legal requirements in your jurisdiction. It's also essential to regularly review and update your policy to reflect any changes in your services or business practices.</p>
									<hr></hr>
									<p><b>7.</b> Payment processor you'll be making use of?</p>
									<p><b>Ans:</b> Support a variety of payment methods to accommodate your users' preferences. Common options include credit/debit cards, PayPal, stripe etc and other online payment systems.</p>
									<hr></hr>
									<p><b>8.</b> What criteria will drivers need to meet to be eligible to work with your platform?</p>
									<p><b>Ans:</b></p><br></br>
									<hr></hr>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Driver's License: </b> Require drivers to possess a valid driver's license for the type of vehicle they will be operating. Check that their license is current and has no &nbsp;&nbsp;&nbsp;&nbsp;major violations.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Age Requirement:  </b> Set a minimum age requirement for drivers. Typically, drivers should be at least 21 years old, but this can vary depending on local &nbsp;&nbsp;&nbsp;&nbsp;regulations.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Vehicle Requirements:  </b>  Conduct thorough background checks on potential drivers. This should include criminal background checks, driving record checks, &nbsp;&nbsp;&nbsp;&nbsp;and possibly drug tests.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Background Check:  </b> Conduct thorough background checks on potential drivers. This should include criminal background checks, driving record checks, and &nbsp;&nbsp;&nbsp;&nbsp;possibly drug tests.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>   Insurance:  </b> Require drivers to have appropriate vehicle insurance that covers them while operating on your platform. This may include liability insurance and &nbsp;&nbsp;&nbsp;&nbsp;coverage for passengers or cargo if applicable.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Vehicle Registration: </b> Ensure that drivers' vehicles are properly registered and meet all legal requirements for operating as commercial vehicles if applicable.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Experience:  </b> Consider setting minimum driving experience requirements. Some platforms require drivers to have a certain number of years of driving &nbsp;&nbsp;&nbsp;&nbsp;experience.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>   Clean Driving Record:  </b> Drivers should have a relatively clean driving record with minimal traffic violations and accidents. Define the maximum number of &nbsp;&nbsp;&nbsp;&nbsp;points or violations allowed within a specific timeframe.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Background Screening:  </b> Conduct a comprehensive background screening, including checks for criminal history, sex offender registry, and other relevant &nbsp;&nbsp;&nbsp;&nbsp;records.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Vehicle Inspection:  </b> Require drivers to undergo regular vehicle inspections to ensure their vehicles meet safety and maintenance standards</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Customer Service Skills:  </b> Depending on your platform, you may want to assess drivers' customer service skills and professionalism, especially if they interact &nbsp;&nbsp;&nbsp;&nbsp;with passengers directly.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Communication Skills:   </b>  Evaluate drivers' communication skills, including their ability to use your platform's communication tools effectively.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>   Language Proficiency:  </b> Determine if drivers need to meet language proficiency requirements, especially if you operate in a multilingual environment.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Geographic Restrictions:   </b> Specify any geographic restrictions or service areas that drivers need to adhere to.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Platform Training:   </b> Provide training on how to use your platform's app or software, including navigation, accepting rides/orders, and handling customer service &nbsp;&nbsp;&nbsp;&nbsp;inquiries.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Safety and Conduct Guidelines:  </b> Clearly communicate your platform's safety and conduct guidelines that drivers must follow when interacting with &nbsp;&nbsp;&nbsp;&nbsp;customers and using your service.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Rating and Performance Standards:  </b> Establish performance standards, including minimum customer ratings or feedback scores, that drivers must maintain to &nbsp;&nbsp;&nbsp;&nbsp;remain active on your platform.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Legal and Regulatory Compliance:  </b> Ensure that drivers comply with all local and national laws and regulations related to transportation services.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Background Rechecks:  </b> Implement periodic background rechecks to ensure that drivers maintain their eligibility throughout their association with your &nbsp;&nbsp;&nbsp;&nbsp;platform.</li>
									</ul></p><br></br>
									<hr></hr>
									<p><b>9.</b> Tell more about the safety and security features you have in place for all users. For example, do you have certain keyword filters in your chats (if you have the chat feature)?</p>
									<p><b>Ans:</b></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>User Verification:</b>Require users to verify their identities using government-issued identification or other reliable methods. Verified users tend to engender &nbsp;&nbsp;&nbsp;greater trust among others on the platform.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Background Checks:</b>Conduct background checks on drivers or service providers to screen for criminal history or other disqualifying factors. Similarly, verify &nbsp;&nbsp;&nbsp;the identities of customers or users as part of your onboarding process.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Secure Authentication:</b>Implement secure authentication methods, such as two-factor authentication (2FA), to protect user accounts from unauthorized &nbsp;&nbsp;&nbsp;access.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Data Encryption:</b>Encrypt user data, both in transit and at rest, to safeguard sensitive information from unauthorized access or data breaches.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Privacy Controls:</b> Allow users to set privacy controls on their profiles, such as who can see their information and contact them.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Report and Block Functionality:</b>Enable users to report inappropriate behavior or content and block other users if necessary.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Chat Moderation:</b> If your platform includes chat features, consider implementing keyword filters to detect and prevent abusive language or harassment. &nbsp;&nbsp;&nbsp;Additionally, allow users to report problematic messages.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Rating and Review Systems:</b>Implement rating and review systems for both drivers/providers and customers/users. Encourage honest and constructive &nbsp;&nbsp;&nbsp;feedback, which can help identify and address safety concerns.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Emergency Features:</b>Provide users with a quick and easy way to contact emergency services or request immediate assistance if they feel unsafe during a &nbsp;&nbsp;&nbsp;transaction or interaction.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Transaction Tracking:</b>Allow users to track the progress of transactions or deliveries in real-time. This transparency can enhance users' sense of security.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Secure Payment Processing:</b>Use reputable and secure payment processors to handle financial transactions securely. Ensure that users' payment information &nbsp;&nbsp;&nbsp;is protected.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Geolocation and Location Sharing:</b>Use geolocation features to enhance user safety during transactions. Share real-time location information with trusted &nbsp;&nbsp;&nbsp;contacts or authorities if necessary.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Customer Support and Reporting:</b>Maintain responsive customer support channels for users to report safety concerns, inappropriate behavior, or any issues &nbsp;&nbsp;&nbsp;they encounter on the platform.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>User Education:</b>Provide resources and educational materials to users about safety best practices, such as verifying the identity of service providers and using &nbsp;&nbsp;&nbsp;secure communication channels.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Terms of Service and Community Guidelines: </b>Clearly outline your platform's terms of service and community guidelines, which should include rules and &nbsp;&nbsp;&nbsp;expectations regarding safety and appropriate behavior.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Incident Response Plan:</b>Develop a comprehensive incident response plan that outlines how the platform will handle safety and security incidents, including &nbsp;&nbsp;&nbsp;communication with users, investigation, and resolution.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Legal and Regulatory Compliance:</b> Ensure that your platform complies with all relevant laws and regulations related to user safety and data protection.</li>
									</ul></p><br></br>
									<hr></hr>
									<p><b>10.</b> Do you require drivers to have insurance, and if so, what type</p>
									<p><b>Ans:</b></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Liability Insurance: </b> Most platforms require drivers to have liability insurance. This insurance type covers damages and injuries caused by the driver to third &nbsp;&nbsp;&nbsp;parties, such as passengers, pedestrians, or other vehicles, in the event of an accident.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Commercial Auto Insurance:</b> In some cases, especially for drivers providing transportation services as a business, you may require drivers to have commercial auto insurance. &nbsp;&nbsp;&nbsp;Commercial auto insurance typically provides coverage for vehicles used for commercial purposes.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Rideshare Insurance:</b>If your platform operates in the rideshare or on-demand transportation industry, you may specify that drivers obtain rideshare &nbsp;&nbsp;&nbsp;insurance. Rideshare insurance is designed to cover drivers who use their personal vehicles for ridesharing services like Uber or Lyft.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Delivery Insurance:</b>For platforms focused on delivery services, drivers may need specific commercial insurance tailored to the transportation of goods or &nbsp;&nbsp;&nbsp;packages. This type of insurance covers potential damages or losses related to the delivery business.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Minimum Coverage Limits:</b>Specify minimum coverage limits for liability insurance. The required coverage amounts may vary by location and regulatory &nbsp;&nbsp;&nbsp;requirements, so check local laws and consult with legal experts to determine appropriate minimums.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Proof of Insurance: </b> Require drivers to provide proof of insurance when they sign up and periodically throughout their association with your platform. This &nbsp;&nbsp;&nbsp;documentation ensures that they maintain the necessary coverage.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Additional Insured: </b>Consider adding your platform as an additional insured party on drivers' insurance policies. This can provide an extra layer of protection in &nbsp;&nbsp;&nbsp;case of incidents related to platform activities.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Compliance with Local Regulations:</b>Ensure that your insurance requirements align with local laws and regulations governing transportation services. &nbsp;&nbsp;&nbsp;Regulatory requirements may vary from one jurisdiction to another.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Insurance Verification:</b>Implement a system for verifying drivers' insurance coverage to ensure that their policies are active and valid.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Education:</b>Educate drivers about the importance of insurance and the specific requirements for coverage while using your platform. Provide resources to &nbsp;&nbsp;&nbsp;help them understand their insurance needs.</li>
									</ul></p><br></br>
									<hr></hr>
									<p><b>11.</b>  How will liability be assigned in case of accidents or property damage during deliveries?</p>
									<p><b>Ans:</b></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Driver Liability:</b><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In most cases, the primary liability for accidents or property damage during deliveries will rest with the driver. This assumes that the driver's actions or &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;negligence contributed to the incident.</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Drivers are typically required to have their own liability insurance coverage, which should cover damages or injuries they cause to third parties, including &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;accidents during deliveries.</li>
									</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Platform Liability:</b><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The platform itself may have a limited level of liability, particularly if the accident or property damage occurred due to a fault or defect in the platform's &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;technology, app, or service.</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The platform's liability may be outlined in the platform's terms of service and contractual agreements with drivers and customers.</li>
									</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Insurance Coverage:</b><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Insurance policies carried by the driver play a crucial role in assigning liability. If the driver has the appropriate commercial auto or delivery insurance, their &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;insurance company may cover damages up to the policy limits.</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Some platforms may also carry commercial insurance policies that provide additional coverage, especially if the driver's insurance is insufficient.</li>
									</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Contractual Agreements:</b><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The terms of service and contractual agreements between the platform, drivers, and customers may specify how liability is assigned in different scenarios.</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;These agreements may include indemnification clauses where drivers agree to assume liability for their actions while using the platform.</li>
									</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Local Laws and Regulations:</b><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The assignment of liability may also be influenced by local laws and regulations governing transportation and delivery services. Legal requirements can &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vary by jurisdiction.</li>
									</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Investigation and Evidence:</b><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In cases of accidents or property damage, investigations may be conducted to determine the cause and responsible parties. Gathering evidence, such as &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;accident reports, witness statements, and vehicle data, can help determine liability.</li>
									</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Dispute Resolution:</b><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The platform may have a dispute resolution process in place to address liability disputes between drivers, customers, and third parties. This process may &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;involve mediation, arbitration, or legal proceedings.</li>
									</li>
									</ul></p><br></br>
									<hr></hr>
									<p><b>12.</b>  Will you comply with specific regulations for handling medical products? This should go into. your compliance policy, but I'd like to know. Privacy policy questions:</p>
									<p><b>Ans:</b></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Licensing and Permits:</b>Ensure that all drivers or delivery personnel handling medical products have the necessary licenses and permits required by local &nbsp;&nbsp;&nbsp;health authorities or regulatory agencies.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Product Handling Guidelines:</b>Establish clear guidelines for the safe handling, storage, and transportation of medical products to prevent contamination, &nbsp;&nbsp;&nbsp;damage, or tampering.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Temperature Control:</b>Implement temperature control measures, such as refrigeration or temperature monitoring, for medical products that require specific &nbsp;&nbsp;&nbsp;temperature ranges to maintain their integrity.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Chain of Custody:</b>Maintain a clear chain of custody for medical products to track their movement from the source to the destination, ensuring accountability &nbsp;&nbsp;&nbsp;and security.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Security Protocols:</b>Implement security protocols to safeguard medical products from theft, unauthorized access, or tampering during transportation and &nbsp;&nbsp;&nbsp;delivery.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Compliance Documentation:</b>Keep comprehensive records of compliance with all relevant regulations and requirements, including documentation of driver &nbsp;&nbsp;&nbsp;qualifications, vehicle inspections, and temperature logs.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Emergency Procedures: </b>Develop and communicate emergency response procedures in case of accidents, spills, or other incidents involving medical &nbsp;&nbsp;&nbsp;products.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>   Training and Education:</b> Provide training to drivers and personnel on the specific handling and safety requirements for medical products, as well as the &nbsp;&nbsp;&nbsp;importance of compliance.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Audit and Inspection:</b> Conduct regular audits and inspections of drivers, vehicles, and facilities to ensure ongoing compliance with medical product handling &nbsp;&nbsp;&nbsp;regulations.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Communication:</b> Clearly communicate your platform's commitment to complying with medical regulations to users, healthcare institutions, and relevant &nbsp;&nbsp;&nbsp;authorities.</li>
									</ul></p><br></br>
									<p><b>Regarding privacy policy questions, here are some common inquiries related to handling medical products and user data that you might address in your privacy policy:</b></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Data Collection:</b>Explain what user data is collected and stored, including any medical-related information if applicable.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>   Data Usage:</b>Specify how user data, including medical-related data, will be used. Make it clear that data will be used solely for the purpose of providing the &nbsp;&nbsp;&nbsp;service and complying with medical regulations.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Data Security:</b>Describe the security measures in place to protect user data, particularly medical data, from unauthorized access or breaches.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Data Sharing: </b> Explain if and when user data, including medical data, will be shared with third parties, and under what circumstances (e.g., for compliance &nbsp;&nbsp;&nbsp;reporting to health authorities).</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>User Consent:</b>Describe how users' consent is obtained for the collection and use of their data, especially medical information.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Data Retention:</b>Specify how long user data, including medical data, will be retained and the procedures for data deletion when it's no longer needed.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>User Rights:</b> Inform users about their rights regarding their data, such as the right to access, correct, or delete their information.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Compliance with Regulations: </b> Emphasize your commitment to complying with all applicable privacy and medical data regulations and provide information on &nbsp;&nbsp;&nbsp;how users can contact you with privacy-related inquiries or concerns.</li>
									</ul></p><br></br>
									<hr></hr>
									<p><b>1. Tell me more about the categories of information you will be collecting from users.</b></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Personal Information: </b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Full name</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date of birth</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gender</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contact information (email address, phone number, mailing address)</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Account Credentials: </b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username or user ID</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Password or PIN for account access</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Payment Information: </b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Credit card or debit card details</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Billing information</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bank account information (for direct debits or transfers)</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Location Data: </b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;GPS data or IP address to determine the user's location</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Location history for service optimization (e.g., for delivery services or ride-sharing)</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gender</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> User-generated Content: </b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Messages, chats, or communication history within the platform</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reviews, ratings, comments, or feedback submitted by users</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Profile Information: </b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;User profile picture or avatar</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Biographical information (e.g., short bio, occupation, interests)</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Device Information:</b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Device type (e.g., smartphone, tablet, computer)</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Operating system and version</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unique device identifiers (e.g., device ID, MAC address)</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Usage Data: </b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;User activity within the platform, such as logins, searches, interactions, and transactions</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Timestamps of actions and interactions</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Health or Medical Data (if applicable): </b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Medical history</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Allergies or medical conditions</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Medication or treatment information (for healthcare-related platforms)</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Driver's License and Vehicle Information (for transportation or delivery platforms): </b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Driver's license number</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vehicle registration details</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Insurance information</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Social Media Information (if integrated): </b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Social media profile data, if users choose to connect their social media accounts (e.g., Facebook, LinkedIn)</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Consent and Preferences: </b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;User preferences, settings, and consent choices related to data collection and notifications</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b> Customer Support and Communication History:</b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Records of customer support inquiries and interactions with customer support agents</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>  Legal and Regulatory Data (if required):</b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Data necessary for compliance with legal or regulatory obligations, such as tax identification numbers or business registration details</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Other Information as Required:</b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Additional data categories that are relevant to the specific services or functionalities of your platform</li>
									</ul></p><br></br>
									<hr></hr>
									<p><b>2. Tell me more about user verification.</b></p><br></br>
									<p><b>Only for driver</b></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>1. Email Verification:</b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One of the most common methods involves sending a verification link or code to the user's registered email address.</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Users are required to click on the link or enter the code to confirm that they have access to the provided email address.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>2. Phone Number Verification:</b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Users are sent a verification code via SMS or voice call to the phone number they provided during registration.</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;They must enter the code to confirm the phone number's ownership.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>3. Social Media Verification:</b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Users can be allowed to sign up or log in using their social media accounts (e.g., Facebook, Google).</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Social media accounts provide an additional layer of verification, as they are often tied to a real identity.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>4. ID Verification:</b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For platforms with higher security requirements, users may be asked to provide a government-issued ID, such as a driver's license or passport.</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;These documents are typically reviewed manually or using automated verification services to confirm the user's identity.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>5. Biometric Verification:</b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Some platforms use biometric data, such as fingerprint scans or facial recognition, to verify a user's identity.</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This method is common in mobile devices with biometric sensors.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>6. Video Verification:</b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Users may be required to provide a video selfie or participate in a video call to confirm their identity.</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This method adds a visual confirmation of the user's identity.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>7. Address Verification:</b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For platforms that require users to receive physical items at a specific address, address verification may be necessary.</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Users may be asked to provide proof of address, such as a utility bill.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>8. Social Verification:</b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Users can verify their identity by connecting with other trusted users on the platform.</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This method relies on social networks within the platform to vouch for a user's authenticity.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>9. Knowledge-Based Verification:</b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Users may be asked to answer personal questions or knowledge-based questions related to their identity.</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;These questions often involve details that only the user should know.</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>10. Continuous Authentication:</b> </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- In some cases, continuous authentication methods, such as behavioral biometrics or device fingerprinting, are used to monitor user behavior and detect &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;suspicious activity.</li>
									</ul></p><br></br>
									<hr></hr>
									<p><b>3. List third-party tools you will use on the</b></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Payment Processors:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Stripe</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PayPal</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Square</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Adyen</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Braintree</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Cloud Hosting and Infrastructure:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Amazon Web Services (AWS)</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Google Cloud Platform (GCP)</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Microsoft Azure</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hostinger</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Heroku</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Authentication and Identity Verification:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Okta</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Auth0</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Onfido</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Jumio</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Communication and Messaging:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Twilio (for SMS and voice communication)</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SendGrid (for email communication)</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Intercom (for in-app messaging and support)</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Analytics and User Behavior Tracking:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Google Analytics</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mixpanel</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Segment</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Amplitude</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Customer Relationship Management (CRM):</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Salesforce</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HubSpot</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Zoho CRM</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Content Delivery and Content Management:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content Delivery Networks (CDNs) like Cloudflare</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content management systems (CMS) like WordPress or Drupal</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Marketing and Advertising:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Google Ads</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Facebook Ads</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mailchimp (for email marketing)</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HubSpot (for inbound marketing)</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Social Media Integration:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Facebook Graph API</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Twitter API</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Instagram API</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Geolocation and Mapping:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Google Maps API</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mapbox</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Here Maps</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Customer Support and Helpdesk:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Zendesk</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Freshdesk</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Help Scout</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Data Analytics and Business Intelligence:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tableau</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Power BI</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Looker</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Security and Data Protection:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cloudflare (for DDoS protection)</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AWS Security Services</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fortinet (for network security)</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Backup and Disaster Recovery:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Veeam</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Veeam</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Legal and Compliance:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DocuSign (for e-signatures)</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TrustArc (for privacy compliance)</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Marketplace and E-commerce Platforms:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WooCommerce (for WordPress)</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Magento</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Data Storage and Databases:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Amazon RDS (Relational Database Service)</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MongoDB Atlas</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Firebase Realtime Database</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Performance Monitoring and Error Tracking:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;New Relic</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sentry</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Datadog</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Machine Learning and AI Services:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Amazon SageMaker</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Google Cloud AI</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Microsoft Azure Machine Learning</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Collaboration and Project Management:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Slack</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trello</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Asana</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Survey and Feedback Tools:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SurveyMonkey</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Typeform</li>
									</ul></p><br></br>
									<p><ul>
									<li>&nbsp;&nbsp;&nbsp;<b>Workflow Automation:</b> </li><br></br>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Zapier</li>
									<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Integromat</li>
									</ul></p><br></br>
									<hr></hr>
									<p><b>4. Will you collect information about users' devices? (e.g. browser type, device OS, IP address, GPS/location services, etc.)</b></p><br></br>
									<li>&nbsp;&nbsp;&nbsp;<b> Browser Type and Version:</b>Collecting data about the user's web browser helps optimize the platform's compatibility and user interface. It can also be used &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to track potential issues related to specific browsers.</li>
									<li>&nbsp;&nbsp;&nbsp;<b>Device Operating System:</b>Information about the user's device operating system (e.g., iOS, Android, Windows) is valuable for ensuring that the platform &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;functions correctly on various devices.</li>
									<li>&nbsp;&nbsp;&nbsp;<b>IP Address:</b> Collecting users' IP addresses can assist with security measures, such as detecting and preventing suspicious activities, as well as &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;understanding the geographic location of users.</li>
									<li>&nbsp;&nbsp;&nbsp;<b>GPS/Location Services:</b>When users grant permission, collecting GPS or location data can enable location-based services, such as providing directions, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;finding nearby services, or matching users with nearby providers or opportunities.</li>
									<li>&nbsp;&nbsp;&nbsp;<b>Device Identifiers:</b>Unique device identifiers, such as device IDs or MAC addresses, may be collected for purposes like user account security and fraud &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;prevention.</li>
									<li>&nbsp;&nbsp;&nbsp;<b>Device Information:</b>Details about the user's device, such as screen size, resolution, and hardware specifications, can be collected to optimize the &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;platform's display and performance.</li><br></br>
									<hr></hr>
									<p><b>5. Will you be sending email newsletters?</b></p>
									<p><b>Ans.</b> yes</p><br></br>
									<hr></hr>
									<p><b>6. Except with authorized third parties such as law enforcement agencies, are you going to sell or share user information with other third parties?</b></p>
									<p><b>Ans.</b> no</p><br></br>
									<hr></hr>
									<p><b>7.  Will you engage in personalized advertising?</b></p>
									<p><b>Ans.</b> yes</p><br></br>
									<hr></hr>
									<p><b>8. Will the website contain links to other websites or adverts from third parties?</b></p>
									<p><b>Ans.</b> no</p><br></br>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		);
    }
}
export default Terms;