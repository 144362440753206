import React, {Component, useState} from 'react';
import ReactDOM from 'react-dom/client';
import { Link, NavLink } from 'react-router-dom';

export const Navbar = () => {
		const [menuOpen, setMenuOpen] = useState(false);
	    return (
			<header className="header">
				<div className="header-inner">
					<div className="container">
						<div className="inner">
							<div className="row">
									<nav className="topnav" id="myTopnav">
										<a href="#" className="icon" onClick={() => setMenuOpen(!menuOpen)}>
											<i className="fa fa-bars"></i>
										</a>
										<Link to="/" className="logo">
											<img className="logo_light" src="/img/logo.png" alt="#" />
											<img className="logo_dark" src="/img/logo-dark.png" alt="#" />
										</Link>
										<ul className={menuOpen ? "responsive" : ""}>
											<li><NavLink className="n_link" to="/">Home</NavLink></li>
											<li><NavLink className="n_link" to="/driver">Drivers</NavLink></li>
											<li><NavLink className="n_link" to="/service">Services</NavLink></li>
											<li><NavLink className="n_link" to="/blog">Blog</NavLink></li>
											<li><NavLink className="n_link" to="/contact">Contact Us</NavLink></li>
										</ul>
										<div className="right-lnk">
											<div className="get-quote">
												<a href="https://redixpresslogistics.com/courier/public/" className="btn">Get Started</a>
											</div>
										</div>
									</nav>
								</div>
							</div>
						</div>
					</div>
				</header>
	     );
}
export default Navbar;