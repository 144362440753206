import React, {Component} from 'react';
class About extends Component {
    render() {
	    return (
		<div className="">
			<div className="breadcrumbs overlay">
				<div className="container">
					<div className="bread-inner">
						<div className="row">
							<div className="col-12">
								<h2>About Us</h2>
								<ul className="bread-list">
									<li><a href="index.html">Home</a></li>
									<li><i className="icofont-simple-right"></i></li>
									<li className="active">About Us</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			
		</div>
		);
    }
}
export default About;