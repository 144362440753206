import React, {Component} from 'react';
class Driver extends Component {
    render() {
	    return (
		<div>
			<div className="breadcrumbs overlay" style={{backgroundImage: 'url("../img/delivery.jpeg")' ,backgroundSize: "cover" , backgroundPosition: "center" , position: "relative" , padding: "150px 0px 75px"}}>
				<div className="container">
					<div className="bread-inner">
						<div className="row">
							<div className="col-12">
								<h2>Drivers</h2>
								<ul className="bread-list">
									<li><a href="index.html">Home</a></li>
									<li><i className="icofont-simple-right"></i></li>
									<li className="active">Drivers</li>
									<li className="active"></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
            <section className="fast_delivery">
			    <div class="container">
				    <div className="row">
						<div className="col-lg-12">
							<p>We offer same day delivery utilizing our network of couriers for packages of any size, dimension, and weight.</p>
						</div>
					</div>
				</div>
			</section>
			<section className="driver-signup section">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-7 col-12">
							<div className="section-title">
								<h2>Get paid to drive, your way.</h2>
								<p>Earn cash on trips you’re already taking, whether you drive across town or across state lines, Redixpress is an easy, flexible way to make money driving.</p>
								<div className="button">
									<a href="https://redixpresslogistics.com/courier/public" className="btn">Driver Sign Up</a>
								</div>
								<div className="row">
								<div className="col-lg-4 col-md-6 col-sm-6">
								<a href="https://apps.apple.com/in/app/redixpress/id6499445593" target='_blank'>
								<img className="rounded get-app-logos" src="img/iphone-store-logo.png" alt="iphone-store-logo"/>
								</a>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-6">
								<a href="#">
								<img className="rounded get-app-logos" src="img/app-store-logo.png" alt="app-store-logo"/>
								</a>
								</div>
								</div>
							</div>
						</div>
						<div className="col-lg-5 col-12">
							<img className="img-fluid rounded align-self-end" src="img/driver-app-img.jpeg" alt="website template image"/>
						</div>
					</div>
				</div>
			</section>
			
			<section className="how_it_work services section">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title">
								<h2>What we provide</h2>
								<p>Here are the ingredients to our recipe for success</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-3 col-md-6 col-6">
							<div className="single-service">
								<i class="fa fa-cubes" aria-hidden="true"></i>
								<h4>CHOOSE YOUR GIGS</h4>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 col-6">
							<div className="single-service">
								<i class="fa fa-building" aria-hidden="true"></i>
								<h4>GIGS MATCHED IN MINUTES</h4>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 col-6">
							<div className="single-service">
								<i class="fa fa-qrcode" aria-hidden="true"></i>
								<h4>YOU’RE IN CONTROL</h4>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 col-6">
							<div className="single-service">
								<i class="fa fa-user-circle-o" aria-hidden="true"></i>
								<h4>TRACK YOUR EARNINGS</h4>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="why_drive services section">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title">
								<h2>Why drive with Redixpress</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-6 col-12">
							<div className="single-service">
								<h4>Make more money per trip</h4>
								<p>Earn an average of $10+ on local Gigs and even more on long haul deliveries.</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-6 col-12">
							<div className="single-service">
								<h4>Drive packages, not people</h4>
								<p>No need to entertain. Sit back and relax during your drive.</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-6 col-12">
							<div className="single-service">
								<h4>Choose your deliveries</h4>
								<p>Choose when, where and how you want to make money.</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-6 col-12">
							<div className="single-service">
								<h4>Know before you go</h4>
								<p>See guaranteed payout, plus pickup and delivery notes upfront.</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-6 col-12">
							<div className="single-service">
								<h4>No minimum vehicle standards</h4>
								<p>Get paid to drive your way, no matter what you drive.</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-6 col-12">
							<div className="single-service">
								<h4>Get help when you need it</h4>
								<p>Live humans are ready to help whenever you need it with 24/7 phone, email and online support.</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			
			<section className="how_it_work services section">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title">
								<h2>FAQ'S</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 col-12">
							<section className="faq-section">
								<div className="faq" id="accordion">
									<div className="card">
										<div className="card-header" id="faqHeading-3">
											<div className="mb-0">
												<h5 className="faq-title" data-toggle="collapse" data-target="#faqCollapse-3" data-aria-expanded="false" data-aria-controls="faqCollapse-3">
													<span className="badge">3</span>Who can become a driver for your service?
												</h5>
											</div>
										</div>
										<div id="faqCollapse-3" className="collapse" aria-labelledby="faqHeading-3" data-parent="#accordion">
											<div className="card-body">
												<p>Anyone with a vehicle and a valid driver's license can become a driver for our service. We welcome both individuals and businesses to join our network.</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-header" id="faqHeading-4">
											<div className="mb-0">
												<h5 className="faq-title" data-toggle="collapse" data-target="#faqCollapse-4" data-aria-expanded="false" data-aria-controls="faqCollapse-4">
													<span className="badge">4</span>Is there a background check for drivers?
												</h5>
											</div>
										</div>
										<div id="faqCollapse-4" className="collapse" aria-labelledby="faqHeading-4" data-parent="#accordion">
											<div className="card-body">
												<p>Yes, all drivers undergo a thorough background check before they can join our platform. This ensures the safety and security of our customers.</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-header" id="faqHeading-14">
											<div className="mb-0">
												<h5 className="faq-title" data-toggle="collapse" data-target="#faqCollapse-14" data-aria-expanded="false" data-aria-controls="faqCollapse-14">
													<span className="badge">14</span>  How can I become a partner or affiliate with your company?
												</h5>
											</div>
										</div>
										<div id="faqCollapse-14" className="collapse" aria-labelledby="faqHeading-14" data-parent="#accordion">
											<div className="card-body">
												<p>If you are interested in partnering with us or becoming an affiliate, please contact our business development team through our website. We are open to exploring mutually beneficial opportunities.</p>
											</div>
										</div>
									</div>
								</div>
								
							</section>
						</div>
					</div>
				</div>
			</section>
            
			{/* <section className="news-single section">
				<div className="container">
					<div className="row g-4">
						<div className="col-lg-3 col-md-6">
							<div className="team-item bg-light">
								<div className="overflow-hidden">
									<img className="img-fluid" src="img/author1.jpg" alt="website template image" />
								</div>
								<div className="text-center p-4">
									<h5 className="mb-0">Instructor Name</h5>
									<small>Designation</small>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="team-item bg-light">
								<div className="overflow-hidden">
									<img className="img-fluid" src="img/author2.jpg" alt="website template image" />
								</div>
								<div className="text-center p-4">
									<h5 className="mb-0">Instructor Name</h5>
									<small>Designation</small>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="team-item bg-light">
								<div className="overflow-hidden">
									<img className="img-fluid" src="img/author1.jpg" alt="website template image" />
								</div>
								<div className="text-center p-4">
									<h5 className="mb-0">Instructor Name</h5>
									<small>Designation</small>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="team-item bg-light">
								<div className="overflow-hidden">
									<img className="img-fluid" src="img/author2.jpg" alt="website template image" />
								</div>
								<div className="text-center p-4">
									<h5 className="mb-0">Instructor Name</h5>
									<small>Designation</small>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="team-item bg-light">
								<div className="overflow-hidden">
									<img className="img-fluid" src="img/author1.jpg" alt="website template image" />
								</div>
								<div className="text-center p-4">
									<h5 className="mb-0">Instructor Name</h5>
									<small>Designation</small>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="team-item bg-light">
								<div className="overflow-hidden">
									<img className="img-fluid" src="img/author2.jpg" alt="website template image" />
								</div>
								<div className="text-center p-4">
									<h5 className="mb-0">Instructor Name</h5>
									<small>Designation</small>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="team-item bg-light">
								<div className="overflow-hidden">
									<img className="img-fluid" src="img/author1.jpg" alt="website template image" />
								</div>
								<div className="text-center p-4">
									<h5 className="mb-0">Instructor Name</h5>
									<small>Designation</small>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="team-item bg-light">
								<div className="overflow-hidden">
									<img className="img-fluid" src="img/author2.jpg" alt="website template image" />
								</div>
								<div className="text-center p-4">
									<h5 className="mb-0">Instructor Name</h5>
									<small>Designation</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}
		</div>
		);
    }
}
export default Driver;