import React, { useState } from 'react';
import Swal from 'sweetalert2'

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', mobile: '', subject: '', message: '', });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateName = (name) => (name.trim() !== '' ? null : 'Enter Your Name');

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email.trim() !== '' && emailRegex.test(email) ? null : 'Enter a Valid Email Address';
  };

  const validatemobile = (mobile) => {
    return mobile.trim() !== ''  ? null : 'Enter a Valid mobile Number';
  };

  const validateSubject = (subject) => (subject.trim() !== '' ? null : 'Please Enter Subject');

  const validateMessage = (message) => (message.trim() !== '' ? null : 'Please Enter Message');

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, mobile, subject, message } = formData;
    const errors = {
      name: validateName(name),
      email: validateEmail(email),
      mobile: validatemobile(mobile),
      subject: validateSubject(subject),
      message: validateMessage(message),
    };

    // if error stops otherwise go
    if (Object.values(errors).some((error) => error !== null)) {
      setErrors(errors);
      return;
    }

    // json object
    const jsonData = {
      name, email, mobile, subject, message,
    };

    //json data console
    console.log(jsonData);
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(jsonData),
      redirect: 'follow'
    };

    fetch("https://redixpresslogistics.com/courier/public/api/savecontact", requestOptions)
      .then(response => response.text())
      .then(result => {
        setFormData({ name: '', email: '', mobile: '', subject: '', message: '' });
        setErrors({});
        Swal.fire( 'Good job!','We will contact you shortly! :)','success');
      })
      .catch(error => console.log('error', error));

    //reset form data after submit
    setFormData({ name: '', email: '', mobile: '', subject: '', message: '' });
    setErrors({});
  };

  return (
    <div>
      <div className="breadcrumbs overlay" style={{ backgroundImage: 'url("../img/driver.jpeg")', backgroundSize: "cover", backgroundPosition: "center", position: "relative", padding: "150px 0px 75px" }}>
      </div>
      <section className="contact-us section section_p30">
        <div className="container">
          <div className="inner">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-us-left">
                  <iframe title="Google Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3006.408907425971!2d-81.46957882413643!3d41.10375987133721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883128f4981d9539%3A0x44f32cadd64c4a75!2sParking%20lot%2C%201502%20Brittain%20Rd%2C%20Akron%2C%20OH%2044310%2C%20USA!5e0!3m2!1sen!2sin!4v1720891925131!5m2!1sen!2sin" width="100%" height="100%" style={{ border: 0 }} loading="lazy"></iframe>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-us-form">
                  <h2>Contact Us</h2>
                  <p>Do you have a rush delivery, medical courier request, or questions about same day delivery? Please do not hesitate to get in contact with our team below. We will get back to you ASAP!</p>
                  <form className="form" method="post" action="">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input type="text" name="name" placeholder="Name" required="" value={formData.name} onChange={handleChange} />
                          {errors.name && <span className="error text-danger">{errors.name}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input type="text" name="email" placeholder="Email" required="" value={formData.email} onChange={handleChange} />
                          {errors.email && <span className="error text-danger">{errors.email}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input type="text" name="mobile" placeholder="mobile" required="" value={formData.mobile} onChange={handleChange} />
                          {errors.mobile && <span className="error text-danger">{errors.mobile}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input type="text" name="subject" placeholder="Subject" required="" value={formData.subject} onChange={handleChange} />
                          {errors.subject && <span className="error text-danger">{errors.subject}</span>}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <textarea name="message" placeholder="Your Message" required="" value={formData.message} onChange={handleChange}></textarea>
                          {errors.message && <span className="error text-danger">{errors.message}</span>}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group login-btn">
                          <button className="btn" type="button" onClick={handleSubmit}>Send</button>
                        </div>
                        <div className="checkbox">
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-info">
            <div className="row">
              <div className="col-lg-4 col-12">
                <div className="single-info">
                  <i className="icofont icofont-ui-call"></i>
                  <div className="content">
                    <h3>330-624-8815</h3>
                    <p>info@redixpress.com</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="single-info">
                  <i className="icofont-google-map"></i>
                  <div className="content">
                    <h3>1502 Brittain Rd #1058 Akron, OH 44310</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="single-info">
                  <i className="icofont icofont-wall-clock"></i>
                  <div className="content">
                    <h3>We operate 24/7</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
